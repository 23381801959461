@import "../node_modules/semantic-ui-css/semantic.min.css";

$padding-s: 5px;
$padding-m: 0.678571em;
$padding-l: 20px;

$white: #ffffff;
$gray-light: #e8e8e8;
$gray-light-alt: #dddddd;
$gray-mid: #aaaaaa;
$gray-dark: #333333;
$line-height: 1.21428571em;
$radius: 0.28571429rem;
$color-info: #1976d2;
$color-info-alt: #bbdefb;
$color-warning: #FFD6D6;

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

.vtg-form-wrap {
  @include clearfix;

  margin: $padding-l auto;
  max-width: 768px;
  padding: $padding-l;
}

.vtg-app-header {
  background: linear-gradient(155deg, $white 20%, $gray-mid 100%);
  border-bottom: 1px solid $gray-mid;
  border-radius: 0 $radius $radius 0;
  font-family: sans-serif;
  font-weight: 300;
  padding: $padding-s;
}

.vtg-row {
  @include clearfix;
}

.vtg-col {
  padding-bottom: $padding-s;
}

@media screen and (min-width: 600px) {
  .vtg-row-1 {
    .vtg-col {
      padding-right: $padding-s;
      width: 100%;
    }
  }

  .vtg-row-2 {
    .vtg-col {
      width: 66%;

      &:first-child {
        width: 34%;
      }
    }
  }

  .vtg-row-3 {
    .vtg-col {
      width: 33%;

      &:first-child {
        width: 34%;
      }
    }
  }

  .vtg-col {
    box-sizing: border-box;
    float: left;
    padding-right: $padding-s;

    &:last-child {
      padding-right: 0;
    }
  }
}

.vtg-type-LABEL {
  font-weight: 500;
  line-height: 1.21428571em;
  padding: $padding-m $padding-s $padding-s;

  &::after {
    content: ":";
  }
}

.vtg-display-field {
  background-color: #f3f3f3;
  border-bottom: 1px solid $gray-light-alt;
  border-radius: $radius;
  box-sizing: border-box;
  line-height: $line-height;
  padding: 0.67857143em 1em;
  text-align: right;
}

.vtg-type-TEXT {
  .ui.input > input {
    text-align: left;
  }
}

.vtg-type-SECTION {
  background: linear-gradient(155deg, $white 20%, $gray-mid 100%);
  border-bottom: 1px solid $gray-mid;
  border-radius: 0 $radius $radius 0;
  margin: $padding-l 0 $padding-m;
  padding: 0 $padding-s 0;

  h2 {
    color: $gray-dark;
    font-family: sans-serif;
    font-size: 1.45em;
    font-weight: 200;
    margin: 0 !important;
    padding: 0;
  }
}

.vtg-type-DISPLAY .ui.input>input {
  background: #f4f4f4;
}

.vtg-disclaimer {
  @include clearfix;

  font-size: 0.8em;
  padding: $padding-l;
  text-align: center;
}

.vtg-dimmer {
  position: fixed !important;
}

// Semantic UI overrides

.ui.header {
  margin-top: $padding-l !important;
}

.ui.divider {
  margin-top: 0;
}

.ui.input > input {
  text-align: right;
}

.ui.label {
  min-width: 36px;
  text-align: center;
}

// Buttons

.vtg-buttons {
  @include clearfix;

  padding-top: $padding-l;

  button {
    box-sizing: border-box;
    display: block;
    margin: 0 0 $padding-l !important;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .vtg-buttons {
    button {
      box-sizing: border-box;
      display: block;
      float: left;
      margin: 0 $padding-l $padding-l 0 !important;
      width: calc(33% - #{$padding-l});

      &:last-child {
        margin-right: 0 !important;
      }

      &:first-child {
        width: 34%;
      }
    }
  }
}

// Print

.vtg-print-out-wrap {
  padding: 10% 10% 5%;
}

.vtg-print-out-header {
  @include clearfix;

  .vtg-print-out-header-left {
    float: left;
    width: 60%;
  }

  .vtg-print-out-header-right {
    float: right;
    width: 40%;
  }
}

.vtg-print-out-header-text {
  padding-top: $padding-m;
  text-align: right;
}

.vtg-print-out {
  margin-bottom: $padding-l;
  width: 100%;

  td {
    font-size: 13px;
    border-bottom: 1px solid $gray-light;
    padding: 0 30px 0 0;
    text-align: right;

    &.vtg-type-TEXT,
    &.vtg-type-LABEL,
    &.vtg-type-SECTION {
      text-align: left;
    }
  }

  tr:last-child {
    td {
      border: 0;
      font-weight: bold;
    }
  }

  strong {
    display: block;
    font-size: 120%;
    padding-top: 20px;
  }

  .vtg-type-SECTION {
    background: none;
    padding-top: $padding-l;

    h2 {
      font-size: 1.6em;
    }
  }
}

.vtg-print-out-title {
  margin-bottom: 0;
  padding-bottom: 0;
}

.vtg-print-out-date {
  display: block;
  padding-bottom: 20px;
}

.vtg-print-out-footer {
  margin-bottom: 0;
  padding: 15px 0 0;
}

// Messages

.vtg-form-field {
  position: relative;
  z-index: 2;
}

.vtg-validator-messages {
  @include clearfix;
}

.vtg-validator-message {
  background-color: $color-warning;
  border-radius:  0 0 $radius $radius;
  margin-top: -0.32em;
  padding: 1em $padding-m $padding-m;
  position: relative;
  z-index: 1;
}
